import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import newRequest from "../../utils/newRequest";
import axios from 'axios';
import upload from "../../utils/upload";
import uploadBgremoval from '../../utils/uploadRemovebg';
import './ImagerenderItem.scss';
import emailjs from '@emailjs/browser';

const ImageRenderItem = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('video'); // Track active tab
  const [imagePrompt, setImagePrompt] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quality, setQuality] = useState('');
  const [angle, setAngle] = useState('');
  const [item, setItem] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [numImages, setNumImages] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCreatingVideo, setIsCreatingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [script, setScript] = useState('');
  const [aspect, setAspect] = useState('16:9');
  const [eleven11voiceId, setEleven11voiceId] = useState('');
  const [savedImages, setSavedImages] = useState([]);
  const [submittedImages, setSubmittedImages] = useState([]);
  const [approvedImages, setApprovedImages] = useState([]);
  const [rejectedImages, setRejectedImages] = useState([]);
  const [savedVideos, setSavedVideos] = useState([]);
  const [submittedVideos, setSubmittedVideos] = useState([]);
  const [approvedVideos, setApprovedVideos] = useState([]);
  const [rejectedVideos, setRejectedVideos] = useState([]);
  const [sellerKey, setSellerKey] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [modelPrompt, setModelPrompt] = useState('');
  const [buyerId, setBuyerId] = useState('');
  const [sellersData, setSellersData] = useState({});
  const [buyersData, setBuyersData] = useState({});
  const [files, setFiles] = useState([]);
  const [backgroundFile, setBackgroundFile] = useState('');
  const [uploading, setUploading] = useState(false); // Add uploading state
  const [itemNames, setItemNames] = useState([]); // New state for item names
  const [itemKey, setItemKey] = useState('');
  const [items, setItems] = useState([]);
  const [sellerAiDesc, setSellerAiDesc] = useState('');
  const [itemIds, setItemIds] = useState([]);
  const [sellerAvatar, setSellerAvatar] = useState('');
  const [alignment, setAlignment] = useState('left');
  const [avatarStyle, setAvatarStyle] = useState('rectangular');
  const [bgURL, setbgURL] = useState('large_window');

  const handleQuality = (e) => {
    setQuality(e.target.value);
  };
    const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleAngle = (e) => {
    setAngle(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  const handleLocation = (e) => {
    setLocation(e.target.value);
  };
  const handleNumImages = (e) => {
    setNumImages(e.target.value);
  };
  const handleScriptChange = (e) => {
    setScript(e.target.value);
  };
  const handleItemChange = (e) => {
    setItem(e.target.value);
  };
  const handleAspectChange = (e) => {
    setAspect(e.target.value);
  };
  const handleAlignmentChange = (e) => {
    setAlignment(e.target.value);
  };
  const handleStyleChange = (e) => {
    setAvatarStyle(e.target.value);
  };

  const generateSessionId = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const createImagePrompt = () => {
    return `/render #quick a ${sellerAiDesc} <${sellerKey}:1> ${angle} ${location}`// + (item !== 'none' ? ` with ${itemKey}` : '');
  };

  const createImageWithItemPrompt = () => {
    if (item && item !== 'none') {
        const selectedItem = items.find(it => it._id === item);
        if (selectedItem) {
            return `/render /size:1024x1024 /vae:GraydientPlatformAPI__bright-vae-xl /sampler:dpm2m /guidance:2 /parser:new /seed:21251 /clipskip:2 /nofix ((high quality, masterpiece, masterwork, cinematic)) ((( ${modelPrompt} <${sellerKey}> holding ${selectedItem.modelPrompt} ${selectedItem.desc} <${selectedItem.finetuneKey}> ${sellerAiDesc} ${angle} ${location} ))) <realvis4light-xl> /steps:35 /images:6 /karras [[[[<fastnegative-xl:-2> low resolution, worst quality, plants, blurry, mediocre, bad art, deformed, disfigured, elongated, mirror reflection, disproportionate, anatomically incorrect, abstract]]]]`;
        }
    }
    return createImagePrompt(); // Fall back to the original prompt if no item is selected
};


document.addEventListener('DOMContentLoaded', () => {
  const imageCards = document.querySelectorAll('.image-card');

  imageCards.forEach((card) => {
    card.addEventListener('click', () => {
      card.classList.toggle('enlarged');
    });
  });
});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const session_id = generateSessionId();
    const imagePrompt = item && item !== 'none' ? createImageWithItemPrompt() : createImagePrompt();

    try {
      await axios.post('https://app.facefwd.ai/api/generate-image', {
        prompt: imagePrompt,
        session_id: session_id
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const pollForImages = async () => {
        try {
          const response = await axios.get(`https://app.facefwd.ai/api/check-image/${session_id}`);
          if (response.data.urls) {
            setImageUrls(response.data.urls);
            setLoading(false);
          } else {
            setTimeout(pollForImages, 5000);
          }
        } catch (error) {
          console.error('Error checking image:', error);
          setTimeout(pollForImages, 5000);
        }
      };

      pollForImages();
    } catch (error) {
      console.error('Error generating image:', error);
      setLoading(false);
    }
  };

  const handleAddImageToVideo = (image) => {
    setSelectedImage(image);
  };

const handleCreateSynthVideo = async (e) => {
  e.preventDefault();
  setIsCreatingVideo(true);
  const callbackId = generateSessionId();
  try {
    const response = await axios.post('https://app.facefwd.ai/api/create-synth-video', {
      aspect,
      alignment,
      avatarStyle,
      bgURL,
      sellerAvatar,
      script,
      callbackId,
    });
    const createdSynthId = response.data.createdSynthId;
    if (!createdSynthId) {
      console.error('No createdSynthId returned from backend');
      setIsCreatingVideo(false);
      return;
    }

    console.log('createdSynthId:', createdSynthId); // Check if the ID is received
    await checkSynthStatus(createdSynthId);
  } catch (err) {
    console.error('Error creating video', err);
    setIsCreatingVideo(false);
  }

};

const checkSynthStatus = async (createdSynthId) => {
  try {
    const interval = setInterval(async () => {
      try {
        // Call backend to check status
        const response = await axios.get(`https://app.facefwd.ai/api/check-synth-status/${createdSynthId}`);
        
        if (response.data.status === 'complete') {
          clearInterval(interval);

          if (response.data.cloudinaryUrl) {
            const cloudinaryUrl = response.data.cloudinaryUrl;
            console.log('Video is complete, Cloudinary URL:', cloudinaryUrl);
            setVideoUrl(cloudinaryUrl);
          } else {
            console.error('No Cloudinary URL returned for complete video');
          }

          setIsCreatingVideo(false);
        } else {
          console.log('Video processing status:', response.data.status);
        }
      } catch (err) {
        console.error('Error during video status check:', err);
        setIsCreatingVideo(false);
        clearInterval(interval);
      }
    }, 5000);
  } catch (err) {
    console.error('Error setting up video status check', err);
    setIsCreatingVideo(false);
  }
};

  const handleCreateVideo = async (e) => {
    e.preventDefault();
    setIsCreatingVideo(true);
    try {
      const selectedImageSrc = selectedImage || imageUrls[0];

      const response = await axios.post(
        "https://api.d-id.com/talks",
        {
          script: {
            type: "text",
            input: script,
            subtitles: 'true',
            provider: { type: 'elevenlabs', voice_id: eleven11voiceId }
          },
          source_url: selectedImageSrc,
          config: {
            stitch: true,
            persist: true,
            name: "Generated Video",
            fluent: false,
          },
        },
        {
          headers: {
            accept: 'application/json',
            'x-api-key-external': '{"elevenlabs": "sk_e5d3a65d2ca406714e1c7a6c1609a07566d30d2f241dd5d4"}',
            'Content-Type': "application/json",
            authorization: `Basic am9uYXRoYW5AZmFjZWZ3ZC5haQ:8PJZ0tC_Ww6cFrUvDDxqh`,
          },
        }
      );

      const createdTalkId = response.data.id;
      await checkTalkStatus(createdTalkId);
    } catch (err) {
      console.error("Error creating video", err);
      setIsCreatingVideo(false);
    }
  };

  const checkTalkStatus = async (talkId) => {
    try {
      const interval = setInterval(async () => {
        const response = await axios.get(`https://api.d-id.com/talks/${talkId}`, {
          headers: {
            Authorization: `Basic am9uYXRoYW5AZmFjZWZ3ZC5haQ:8PJZ0tC_Ww6cFrUvDDxqh`,
            "Content-Type": "application/json",
          },
        });
        if (response.data.status === "done") {
          clearInterval(interval);
          const videoUrl = response.data.result_url;
          const cloudinaryUrl = await uploadVideoToCloudinary(videoUrl);
          setVideoUrl(cloudinaryUrl);
          setIsCreatingVideo(false);
        }
      }, 5000);
    } catch (err) {
      console.error("Error fetching video status", err);
      setIsCreatingVideo(false);
    }
  };

  const uploadVideoToCloudinary = async (videoUrl) => {
    try {
      const response = await axios.post('https://app.facefwd.ai/upload-video', { videoUrl });
      return response.data.url;
    } catch (err) {
      console.error('Error uploading video to Cloudinary', err);
      return "";
    }
  };

  const handleSaveImage = async (url) => {
    const updatedSavedImages = [...savedImages, url];
    setSavedImages(updatedSavedImages);
    try {
      await newRequest.put(`/projects/${id}`, { savedImages: updatedSavedImages });
    } catch (err) {
      console.error("Error saving image", err);
    }
  };

  useEffect(() => {
    if (sellerId && buyerId) {
      const fetchSellersAndBuyersData = async () => {
        try {
          const sellerRes = await newRequest.get(`/users/${sellerId}`);
          const buyerRes = await newRequest.get(`/users/${buyerId}`);

          setSellersData(sellerRes.data);
          setBuyersData(buyerRes.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchSellersAndBuyersData();
    }
  }, [sellerId, buyerId]);

  const handleSubmitImage = async (url) => {
    const updatedSubmittedImages = [...submittedImages, url];
    setSubmittedImages(updatedSubmittedImages);

    try {
      await newRequest.put(`/projects/${id}`, { submittedImages: updatedSubmittedImages });
    } catch (err) {
      console.error("Error submitting image", err);
    }

    const seller = sellersData;
    const buyer = buyersData;

    const templateParams = {
      to_email: seller.email,
      from_email: buyer.email,
      reviewUrl: `https://facefwd.ai/projects/${id}`,
      to_name: seller.username,
      from_name: buyer.username,
    };

    emailjs.send('service_grcioxh', 'template_ep2kfkw', templateParams, {
      publicKey: 'SDCQ1BJyk-nqr804U',
    }).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
      },
      (error) => {
        console.log('FAILED...', error);
      }
    );
  };

  const handleDeleteImage = async (url) => {
    try {
      await newRequest.delete(`/projects/${id}`, { data: { savedImages: url } });
      setSavedImages(savedImages.filter(image => image !== url));
    } catch (err) {
      console.error("Error deleting image", err);
    }
  };

  const handleSaveVideo = async (url) => {
    const updatedSavedVideos = [...savedVideos, url];
    setSavedVideos(updatedSavedVideos);
    try {
      await newRequest.put(`/projects/${id}`, { savedVideo: updatedSavedVideos });
    } catch (err) {
      console.error("Error saving video", err);
    }
  };

  const handleSubmitVideo = async (url) => {
    setSubmittedVideos([...submittedVideos, url]);
    try {
      await newRequest.put(`/projects/${id}`, { submittedVideo: url });
    } catch (err) {
      console.error("Error submitting video", err);
    }
  };

  const handleDeleteVideo = async (url) => {
    try {
      await newRequest.delete(`/projects/${id}`, { data: { savedVideo: url } });
      setSavedVideos(savedVideos.filter(video => video !== url));
    } catch (err) {
      console.error("Error deleting video", err);
    }
  };

  const handleDownloadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  const fetchItems = async (itemIds) => {
    try {
        const response = await newRequest.get(`/item/${itemIds.join(',')}`);
        if (response.status === 200) {
            const items = response.data;
            console.log("Fetched items:", items); // Debugging line
            return items; // Return the fetched items
        } else {
            console.error("Failed to fetch items:", response.status);
            return [];
        }
    } catch (err) {
        console.error("Error fetching items:", err);
        return [];
    }
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await newRequest.get(`/projects/${id}`);
        const data = response.data;
        setSavedImages(data.savedImages || []);
        setSubmittedImages(data.submittedImages || []);
        setApprovedImages(data.approvedImages || []);
        setRejectedImages(data.rejectedImages || []);
        setSavedVideos(data.savedVideo || []);
        setSubmittedVideos(data.submittedVideo || []);
        setApprovedVideos(data.approvedVideo || []);
        setRejectedVideos(data.rejectedVideo || []);
        setSellerId(data.sellerId);
        setBuyerId(data.buyerId);
        setItemIds(data.itemIds);
  
        // Fetch items using the itemIds array
      if (data.itemIds && data.itemIds.length > 0) {
        const fetchedItems = await fetchItems(data.itemIds);
        setItems(fetchedItems); // Set the full item data
        setItemNames(fetchedItems.map(item => ({
          itemId: item._id,
          itemName: item.itemName,
          itemDesc: item.desc,
          itemFinetineKey: item.finetuneKey
        })));
      }
  
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };
  
    fetchData();
  }, [id]);

  useEffect(() => {
    if (sellerId) {
      const fetchSellerData = async () => {
        try {
          const sellerRes = await newRequest.get(`/users/${sellerId}`);
          setSellerKey(sellerRes.data.modelKey);
          setEleven11voiceId(sellerRes.data.eleven11voiceId);
          setSellerAiDesc(sellerRes.data.desc);
          setModelPrompt(sellerRes.data.modelPrompt);
          setSellerAvatar(sellerRes.data.sellerAvatar);
        } catch (error) {
          console.error('Error fetching seller data:', error);
        }
      };

      fetchSellerData();
    }
  }, [sellerId]);

  const handleUploadItem = async () => {
  const newItemName = prompt("Enter item name");
  const newItemCaption = prompt("Enter a short description (ie:white and green container");
  if (!newItemName) {
    alert("Item name is required to proceed with the upload.");
    return;
  }
  setUploading(true);
  try {
    // Upload the files to Cloudinary
    const cloudinaryUrls = await Promise.all(
      [...files].map(async (file) => {
        const itemUrl = await upload(file);
        return itemUrl;
      })
    );
    console.log('Cloudinary URLs:', cloudinaryUrls);


    // Create the item on the server
    const createItemResponse = await newRequest.post('/item', {
      itemName: newItemName,
      itemUrls: cloudinaryUrls,
      projectId: id, // Assuming the project ID should be linked to the item
      desc: newItemCaption,
    });

    const itemId = createItemResponse.data._id; // Assuming the response contains the new item ID

    console.log('Item created with ID:', itemId);
    
    // Update the project with the new item id
    await newRequest.put(`/projects/${id}`, {
      $push: { itemIds: itemId }
    });
console.log('Project updated with new item ID:', itemId);
   
// Catalog the uploaded images in your system
    const catalogResponse = await axios.post('https://app.facefwd.ai/api/upload-and-catalog-item', {
      itemUrls: cloudinaryUrls,
      group_id: newItemName, 
      caption: newItemCaption,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log('Catalog response:', catalogResponse.data);

    console.log('Item created:', createItemResponse.data);

  } catch (err) {
    console.error('Error during item upload and creation:', err);
  } finally {
    setUploading(false);
  }
};

const handleUploadBgImage = async () => {
  setUploading(true);
  try {
    if (backgroundFile) {
      // Upload the background file to Cloudinary
      const uploadedBgURL = await upload(backgroundFile); // Upload the single file
      setbgURL(uploadedBgURL); // Set the bgURL from the upload response
      console.log('Background URL:', uploadedBgURL); // Log the background URL
    } else {
      console.error("No background file selected");
    }
  } catch (error) {
    console.error('Error uploading background image or video:', error);
  } finally {
    setUploading(false);
  }
};

const synthUploadBgImage = async () => {
  setUploading(true);
  try {
    if (backgroundFile) {
      const fileType = backgroundFile.type;
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'video/mp4', 'video/webm'];
      if (!acceptedTypes.includes(fileType)) {
        console.error("Unsupported file type. Please upload a valid image or video.");
        setUploading(false);
        return;
      }
      // Create a FormData object to handle file upload
      const formData = new FormData();
      formData.append('file', backgroundFile);

      const response = await axios.post('https://api.synthesia.io/v2/assets',
      {
        data: formData,
      },
      {
        headers: {
          accept: 'application/json',
          'content-type': fileType,
          Authorization: '53eb4d609897c6583ef2943be00bb0a5',
        },
      }

      );

      // Extract the URL from the response and update the bgURL state
      const uploadedBgURL = response.data.id; // Assuming the response contains the URL in `url` field
      setbgURL(uploadedBgURL); // Set the bgURL from the upload response
      console.log('Background URL:', uploadedBgURL); // Log the background URL for debugging
    } else {
      console.error("No background file selected");
    }
  } catch (error) {
    console.error('Error uploading background image or video:', error);
  } finally {
    setUploading(false);
  }
};

const handleserverBgUpload = async (e) => {
  e.preventDefault();
  setUploading(true);
  try {
    if (backgroundFile) {
      const fileType = backgroundFile.type;
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/svg+xml', 'video/mp4', 'video/webm'];
      if (!acceptedTypes.includes(fileType)) {
        console.error("Unsupported file type. Please upload a valid image or video.");
        setUploading(false);
        return;
      }

      // Convert file to base64 string
      const base64String = await convertToBase64(backgroundFile);

      // Create a payload with base64 string
      const formData = {
        data: `data:${fileType};base64,${base64String}`,  // Base64 data for image or video
        fileName: backgroundFile.name,  // Send the file name if needed
      };

      // Make the API call to your backend
      const response = await axios.post('https://app.facefwd.ai/api/upload-synth-bg', formData);

      // Extract the URL from the response and update the bgURL state
      const uploadedBgURL = response.data.uploadedBgURL;
      setbgURL(uploadedBgURL);  // Set the bgURL from the upload response
      console.log('Background URL:', uploadedBgURL);
    } else {
      console.error("No background file selected");
    }
  } catch (error) {
    console.error('Error uploading background image or video:', error);
  } finally {
    setUploading(false);
  }
};

// Helper function to convert file to base64
const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Return base64 string only
    reader.onerror = (error) => reject(error);
  });
};

return (
  <div className="imageRender">
    <h1>Image Generator and Video Creation</h1>
    <div className="tab-container">
      <button
        className={activeTab === 'image' ? 'active' : ''}
        onClick={() => handleTabChange('image')}
      >
        Image Generation
      </button>
      <button
        className={activeTab === 'video' ? 'active' : ''}
        onClick={() => handleTabChange('video')}
      >
        Video Generation
      </button>
    </div>
    <div className="row-container">
      <div className="left-container">
        {activeTab === 'image' && (
          <>
            <form onSubmit={handleSubmit}>
              <label htmlFor="angle">Enter the image angle:</label>
              <input
                type="text"
                placeholder="facing forward"
                id="angle"
                value={angle}
                onChange={handleAngle}
              />
              {/* <label htmlFor="description">Enter the description:</label>
              <input
                type="text"
                id="description"
                placeholder="a 35 year old white man with dirty blonde hair and blue eyes"
                value={description}
                onChange={handleDescription}
              /> */}
              <label htmlFor="location">Enter the location and action:</label>
              <input
                type="text"
                placeholder="dressed as a jedi knight holding a green lightsaber"
                id="location"
                value={location}
                onChange={handleLocation}
              />
              <label htmlFor="item">Select an Item:</label>
              <select name="item" id="item" onChange={handleItemChange}>
                <option value="none">None</option>
                {itemNames.map((item, index) => (
                  <option key={index} value={item.itemId}>
                    {item.itemName}
                  </option>
                ))}
              </select>
              <button type="submit" disabled={loading}>
                {loading ? 'Generating...' : 'Generate Image'}
              </button>
            </form>

            <form>
              <label htmlFor="">
                Upload 5-7 images of the product you would like to create an item of
              </label>
              <input
                type="file"
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              <button type="button" onClick={handleUploadItem}>
                {uploading ? 'Uploading' : 'Upload'}
              </button>
            </form>
          </>
        )}

        {activeTab === 'video' && (
          <div className="video-creation-section">
            <h2>Video Creation</h2>
            <form onSubmit={handleCreateSynthVideo}>
              <label htmlFor="script">Enter the script for the video:</label>
              <textarea
                id="script"
                value={script}
                onChange={handleScriptChange}
                required
              />
              <label htmlFor="aspect">Select Aspect Ratio</label>
              <select name="Aspect Ratio" id="aspect" onChange={handleAspectChange}>
                <option value="16:9">16:9</option>
                <option value="9:16">9:16</option>
                <option value="1:1">1:1</option>
                <option value="4:5">4:5</option>
                <option value="5:4">5:4</option>
              </select>
              <label htmlFor="alingment">Select Model Alignment</label>
              <select name="Model Position" id="alignment" onChange={handleAlignmentChange}>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
              <label htmlFor="style">Select Model Style</label>
              <select name="Model Style" id="style" onChange={handleStyleChange}>
                <option value="rectangular">Rectangular</option>
                <option value="circular">Circular</option>
              </select>
              <label htmlFor="">
                Upload a background image or video
              </label>
              <input
  type="file"
  onChange={(e) => setBackgroundFile(e.target.files[0])} // Get the single file
/>
              <button type="button" onClick={handleserverBgUpload}>
                {uploading ? 'Uploading' : 'Upload'}
              </button>
              <button type="submit" disabled={isCreatingVideo}>
                {isCreatingVideo ? 'Creating Video...' : 'Create Video'}
              </button>
            </form>
          </div>
        )}
      </div>

      <div className="right-container">
        {activeTab === 'image' && imageUrls.length > 0 && (
          <div className="image-result">
            <h2>Generated Images:</h2>
            <div className="images-grid">
              <div className="images-overlay">
                {imageUrls.map((url, idx) => (
                  <div key={idx} className={`image-card ${url === selectedImage ? 'selected' : ''}`}>
                    <img src={url} alt={`Generated ${idx + 1}`} onClick={() => handleAddImageToVideo(url)} />
                    <div className="image-card-buttons">
                      <button onClick={() => handleSaveImage(url)}>Save</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {activeTab === 'image' && savedImages.length > 0 && (
          <div className="image-result">
            <h2>Saved Images:</h2>
            <div className="images-grid">
              {savedImages.map((url, idx) => (
                <div key={idx} className="image-card">
                  <img src={url} alt={`Saved ${idx + 1}`} />
                  <div className="image-card-buttons">
                    <button onClick={() => handleSubmitImage(url)}>Submit</button>
                    <button onClick={() => handleDeleteImage(url)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'image' && submittedImages.length > 0 && (
          <div className="image-result">
            <h2>Submitted Images:</h2>
            <div className="images-grid">
              {submittedImages.map((url, idx) => (
                <div key={idx} className="image-card">
                  <img src={url} alt={`Submitted ${idx + 1}`} />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'image' && approvedImages.length > 0 && (
          <div className="image-result">
            <h2>Approved Images:</h2>
            <div className="images-grid">
              {approvedImages.map((url, idx) => (
                <div key={idx} className="image-card">
                  <img src={url} alt={`Approved ${idx + 1}`} />
                  <div className="image-card-buttons">
                    <button onClick={() => handleDownloadImage(url)}>Download</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'image' && rejectedImages.length > 0 && (
          <div className="image-result">
            <h2>Rejected Images:</h2>
            <div className="images-grid">
              {rejectedImages.map((url, idx) => (
                <div key={idx} className="image-card">
                  <img src={url} alt={`Rejected ${idx + 1}`} />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'video' && videoUrl && (
          <div className="video-section">
            <h2>Video Preview:</h2>
            <div className="videos-grid">
              <div className="video-card">
                <video src={videoUrl} controls>
                  Your browser does not support the video tag.
                </video>
                <div className="video-card-buttons">
                  <button onClick={() => handleSaveVideo(videoUrl)}>Save</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === 'video' && savedVideos.length > 0 && (
          <div className="video-section">
            <h2>Saved Videos:</h2>
            <div className="videos-grid">
              {savedVideos.map((url, idx) => (
                <div key={idx} className="video-card">
                  <video src={url} controls />
                  <div className="video-card-buttons">
                    <button onClick={() => handleSubmitVideo(url)}>Submit</button>
                    <button onClick={() => handleDeleteVideo(url)}>Delete</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'video' && submittedVideos.length > 0 && (
          <div className="video-section">
            <h2>Submitted Videos:</h2>
            <div className="videos-grid">
              {submittedVideos.map((url, idx) => (
                <div key={idx} className="video-card">
                  <video src={url} controls />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'video' && approvedVideos.length > 0 && (
          <div className="video-section">
            <h2>Approved Videos:</h2>
            <div className="videos-grid">
              {approvedVideos.map((url, idx) => (
                <div key={idx} className="video-card">
                  <video src={url} controls />
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'video' && rejectedVideos.length > 0 && (
          <div className="video-section">
            <h2>Rejected Videos:</h2>
            <div className="videos-grid">
              {rejectedVideos.map((url, idx) => (
                <div key={idx} className="video-card">
                  <video src={url} controls />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);
};

export default ImageRenderItem;